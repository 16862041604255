<template>
<b-card>

    <b-row>

        <!-- User Info: Left col -->
        <b-col cols="12" xl="6" class="d-flex justify-content-between flex-column">
            <!-- User Avatar & Action Buttons -->
            <div class="d-flex justify-content-start">
                <b-avatar :src="userData.avatar" :text="avatarText(userData.first_name)" :variant="`light-${resolveUserRoleVariant(userData.role_name)}`" size="104px" rounded />
                <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                        <h4 class="mb-0">
                            {{ userData.first_name }} {{ userData.middle_name }}
                        </h4>
                        <span class="card-text">{{ userData.email_address }}</span>
                    </div>
                    <div class="d-flex flex-wrap">
                        <b-button :to="{ name: 'apps-users-edit', params: { id: userData.user_id } }" variant="primary">
                            Edit
                        </b-button>
                        <b-button variant="outline-danger" class="ml-1"  v-b-modal.modal-login>
                            Disable
                        </b-button>
                    </div>
                </div>
            </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col cols="12" xl="6">
            <table class="mt-2 mt-xl-0 w-100">
                <tr>
                    <th class="pb-50">
                        <feather-icon icon="UserIcon" class="mr-75" />
                        <span class="font-weight-bold">Username</span>
                    </th>
                    <td class="pb-50">
                        {{ userData.full_names }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                        <feather-icon icon="CheckIcon" class="mr-75" />
                        <span class="font-weight-bold">Status</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                        {{ userData.account_status | statusVerified }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                        <feather-icon icon="StarIcon" class="mr-75" />
                        <span class="font-weight-bold">Role</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                        {{ userData.role_name }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                        <feather-icon icon="PhoneIcon" class="mr-75" />
                        <span class="font-weight-bold">Phone</span>
                    </th>
                    <td class="pb-50">
                        {{ userData.msisdn }}
                    </td>
                </tr>
            </table>
        </b-col>
        <!-- modal login-->
        <b-modal
        id="modal-login"
        cancel-variant="outline-secondary"
        ok-title="Disable"
        cancel-title="Close"
        centered
        title="Reason for Disabling Accounts"
        @ok="disabledAction"
        >
        <b-form>
            <b-form-group>
            <div class="form-label-group">
                <b-form-textarea
                id="textarea"
                rows="3"
                placeholder="Reason"
                v-model="reason"
                required
                />
                <label for="label-textarea">Reason</label>
            </div>
            </b-form-group>
           
        </b-form>
        </b-modal>
    </b-row>
</b-card>
</template>

<script>
import {
    BCard,
    BButton,
    BAvatar,
    BRow,
    BCol,
    BFormTextarea,
     BModal, VBModal, BForm, BFormInput, BFormGroup
} from 'bootstrap-vue'
import {
    avatarText
} from '@core/utils/filter'
import useUsersList from '../users-list/useUsersList'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import $ from 'jquery'

export default {
    components: {
        BCard,
        BButton,
        BRow,
        BCol,
        BAvatar,
        BFormTextarea,
        BModal, VBModal, BForm, BFormInput, BFormGroup
    },
    props: {
        userData: {
            type: Object,
            required: true,
        },
    },
    setup() {
        const {
            resolveUserRoleVariant
        } = useUsersList()
        return {
            avatarText,
            resolveUserRoleVariant,
        }
    },
    data() {
        return {
            reason: "",
        }
    },
    methods: {
        getAllUserLogs() {

        },
        disabledAction() {
            let vm  = this
            $.post({
                url: store.state.rootUrl + 'account/v1/view/users',
                type: "POST",
                async: true,
                crossDomain: true,
                data: JSON.stringify({
                    reason: vm.reason,
                    status: 3,
                    clientId: vm.$cookies.get("clientData").clientID
                }),
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                success: function (response, status, jQxhr) {

                    vm.user = response.data.data.data[0]
                },
                error: function (jQxhr, status, error) {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Error fetching users list',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })
                }
            });
        }
    },
    filters: {
        statusVerified(value) {
            if (value == 1) {
                return "Active"
            } else if (value == 2) {
                return "Unverified"
            } else if (value == 3) {
                return "Disabled"
            } else {
                return "Pending"
            }
        },
    }
}
</script>

<style>

</style>
