<template>
<b-card >
    <b-card-body>
        <b-card-title>Logs</b-card-title>
        <b-card-sub-title>Last 10 Logs for {{userData.first_name}}</b-card-sub-title><br />
        <app-timeline>
        <!-- 12 INVOICES HAVE BEEN PAID -->
        <app-timeline-item v-for="(log,index) in logs" :key="index" :variant="log.action | actionVariant">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6>{{log.action}}</h6>
                <small class="text-muted">{{log.created | formatDate}}</small>
            </div>
            <p style="font-size:11px">{{log.description}}</p>

        </app-timeline-item>
        
    </app-timeline>
    </b-card-body>
    
</b-card>
</template>

<script>
import {
    BCard,
    BCardTitle,
    BCardSubTitle,
    BImg,
    BMedia,
    BAvatar,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import moment from "moment";
import store from '@/store'
import $ from 'jquery'
export default {
    components: {
        BCard,
        BCardTitle,
    BCardSubTitle,
        BImg,
        BMedia,
        BAvatar,
        AppTimeline,
        AppTimelineItem,
    },
    data() {
        return {
            logs: [],
            limit: 10
        }
    },
    props: {
        userData: {
            type: Object,
            required: true,
        },
    },
    watch: {
        userData(newValue) {
            if (newValue) {
                this.getAllLogs()
            }

        }
    },
    methods: {
        getAllLogs() {
            let vm = this
            $.get({
                url: store.state.rootUrl + 'account/v1/view/user/audit_logs',
                type: "get",
                async: true,
                crossDomain: true,
                data: {
                    userMapId: vm.userData.user_mapId,
                    limit: vm.limit
                },
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                success: function (response, status, jQxhr) {

                    vm.logs = response.data.data.data
                },
                error: function (jQxhr, status, error) {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Error fetching users list',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })
                }
            });
        }
    },
    filters: {
        actionVariant(value) {
            if (value == 'messageCreateAction') {
                return "success"
            } else {
                return "info"
            }
        },
        formatDate: function (value) {
            if (!value) return "";
            return moment(value).fromNow();
        },
    }
}
</script>

<style>
.timeline-item[data-v-ffb3c5da]:not(:last-of-type) {
    padding-bottom: 0rem;
}
</style>
