<template>
<b-card no-body>
    <b-card-body>
        <b-card-title>Permissions</b-card-title>
        <b-card-sub-title>Permission according to Services {{userData.first_name}}</b-card-sub-title>
    </b-card-body>
    <b-table striped responsive :fields="columns" :items="permissions" class="mb-0">

        <template #cell(permission)="data">
            {{ data.item.permission }}
        </template>
        <template #cell(status)="data">
            <b-form-checkbox disabled :checked="data.item.status | statusVerified" />
        </template>

    </b-table>
</b-card>
</template>

<script>
import store from '@/store'
import $ from 'jquery'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
} from 'bootstrap-vue'

export default {
    components: {
        BCard,
        BTable,
        BCardBody,
        BCardTitle,
        BCardSubTitle,
        BFormCheckbox,
    },
    setup() {
        const permissionsData = []

        return {
            permissionsData,
        }
    },
    data() {
        return {
            permissions: [],
            columns: [{
                    key: 'permission',
                    sortable: true
                },
                {
                    key: 'status',
                },

            ]
        }
    },
    props: {
    userData: {
      type: Object,
      required: true,
    },
  },
    watch: {
      userData(newValue) {
        if(newValue){
          console.log("Data New "+JSON.stringify(newValue))
          this.getAllPermission()
        }
        
      }
    },
    methods: {
        getAllPermission() {
            let vm = this

            $.get({
                url: store.state.rootUrl + 'account/v1/view/user_permissions',
                type: "get",
                async: true,
                crossDomain: true,
                data: {
                    userMapId: vm.userData.user_mapId
                },
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                success: function (response, status, jQxhr) {

                    vm.permissions = response.data.data
                },
                error: function (jQxhr, status, error) {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Error fetching users list',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })
                }
            });
        }
    },
     filters: {
        statusVerified(value){
            if(value == 1){
                return true
            }
            else {
                return false
            }
        },
     }
}
</script>

<style>

</style>
